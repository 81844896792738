$body-bg: #f2f2f2;
$body-color: #000;
$link-color: #e58e26;
$dark: #3c6382;
$darker: #0a3d62;
$orange: #e58e26;
$muted-gray: #6a89cc;
$enable-shadows: true;

$font-family-base: 'Montserrat', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.75;

$fa-font-path: "../assets/fonts";
